import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";
import { DocumentUploadInfo, FileMetadata, UploadedImage } from "@frontend/models";
import { PropupDocumentType } from "packages/frontend/src/app/models/document-type";
import { Apollo } from "apollo-angular";
import { HttpClient, HttpEvent } from "@angular/common/http";
import { FormArray, FormGroup } from "@angular/forms";
import { environment } from "@frontend/envs/environment";
import { GraphQlResponseSaveStorageUrl, SAVE_STORAGE_URL } from "@frontend/graphql/mutation";

@Injectable({ providedIn: 'root' })
export class FileUploadService {
  OBJECT_BACKEND_URL = environment.OBJECT_BACKEND_URL;

  constructor(
    private readonly _apollo: Apollo,
    private readonly _http: HttpClient,
  ) { }

  uploadDocument(uploadInfo: DocumentUploadInfo): Observable<HttpEvent<Object>> {
    const formData = this.buildFormData(uploadInfo, 'file', 'filemetadata');

    const serviceResult = this.postFiles(formData);

    return serviceResult;
  }

  private buildFormData(doc: DocumentUploadInfo, fileFieldName: string, metadataFieldName: string): FormData {
    const metadata: FileMetadata[] = [{
      id: doc.id,
      parentId: doc.parentId ?? '',
      type: doc.type,
    }];

    const formData = new FormData();
    formData.append(fileFieldName, doc.data);
    formData.append(metadataFieldName, JSON.stringify(metadata));

    return formData;
  }

  postFiles(formData: FormData): Observable<HttpEvent<Object>> {
    const url = `${this.OBJECT_BACKEND_URL}customer-files/documents`;
    return this._http.post(url, formData, {
      reportProgress: true,
      observe: 'events',
    }).pipe(shareReplay());
  }

  saveStorageUrls(
    uploadedImages: UploadedImage[],
    formArray: FormArray,
    removeControlNames: string[],
  ): void {
    uploadedImages.forEach((img) => {
      const formGroup = formArray.controls.find((fg) => fg.value.id === img.id);

      if (formGroup) {
        removeControlNames.forEach(name => {
          (formGroup as FormGroup).removeControl(name);
        });
        formGroup.patchValue({
          storageUrl: img.storageUrl,
          uploadStatus: 'DONE',
        });
      } else {
        console.warn(
          'saveStorageUrls',
          `There is no file in the FormGroup with id ${img.id}`
        );
      }
    });
  }

  saveUrl(data: UploadedImage, type: PropupDocumentType, accessId: string, relatedCustomerId?: string): Observable<string> {
    return this._apollo.mutate<
      GraphQlResponseSaveStorageUrl,
      { saveStorageUrl: UploadedImage; type: PropupDocumentType, accessId: string }
    >({
      mutation: SAVE_STORAGE_URL,
      variables: { saveStorageUrl: { ...data, relatedCustomerId }, type, accessId },
    }).pipe(
      map((m: any) => m.data.saveStorageUrl),
    );
  }

}