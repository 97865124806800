import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'OfferDecisionPipe'
})

export class OfferDecisionPipe implements PipeTransform {
    transform(value?: string | null): string {
        if (!value) {
            return 'Offen';
        }

        switch (value) {
            case 'notDefined':
            case 'DECISSIONPENDING':
                return 'Offen';
            case 'accepted':
            case 'ACCEPTANCE':
                return 'Akzeptiert';
            case 'notAccepted':
            case 'NOTACCEPTED':
                return 'Abgelehnt';
            case 'ACCEPTANCEWITHCHANGE':
                return 'Akzeptiert mit Änderung';
            default:
                return 'Offen';
        }
    }
}