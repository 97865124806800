import { gql } from 'apollo-angular';

export interface GraphQlResponseSaveStorageUrl {
  saveStorageUrl: string;
}

export const SAVE_STORAGE_URL = gql`
  mutation SaveStorageUrl($saveStorageUrl: SaveStorageUrlInput!, $type: String!, $accessId: String!) {
    saveStorageUrl(saveStorageUrl: $saveStorageUrl, type: $type, accessId: $accessId)
  }
`;