import { Pipe, PipeTransform } from '@angular/core';
import { PropertyAddress } from '@frontend/models';

@Pipe({ name: 'displayAddress' })
export class DisplayAddressPipe implements PipeTransform {
  transform(value: PropertyAddress | undefined): string {
    let result = '';

    if (!value) {
      return result;
    }

    const {
      streetName,
      buildingNumber,
      zipCode,
      city
    } = value;

    if (streetName) {
      result += streetName;
    }

    if (buildingNumber) {
      result += `${result && ' '}${buildingNumber}`;
    }

    if (zipCode) {
      result += `${result && ', '}${zipCode}`;
    }

    if (city) {
      result += `${result && ' '}${city}`;
    }

    return result.trim();
  }
}
