import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'RentDurationPipe'
})

export class RentDurationPipe implements PipeTransform {
    transform(value?: string | null): string {
        if (!value) {
            return 'Offen';
        }

        switch (value) {
            case 'Unbefristet':
                return 'Unbefristet';
            case 'Befristet':
                return 'Befristet';
            default:
                return '';
        }
    }
}