import { Component } from '@angular/core';
import { ThemeService } from '@frontend/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  isRemaxTheme$ = this._themeService.isRemaxTheme$;
  isPropupTheme$ = this._themeService.isPropupTheme$;
  isSulekTheme$ = this._themeService.isSulkeTheme$;
  isWohnCloudTheme$ = this._themeService.isWohnCloudTheme$;
  isGraslTheme$ = this._themeService.isGraslTheme$;
  isImmo4Theme$ = this._themeService.isImmo4Theme$;
  isImmobilienMelkTheme$ = this._themeService.isImmobilienMelkTheme$;
  isAlteraTheme$ = this._themeService.isAlteraTheme$;
  isFinovaTheme$ = this._themeService.isFinovaTheme$;
  isHagsteinerTheme$ = this._themeService.isHagsteinerTheme$;
  isQualisTheme$ = this._themeService.isQualisTheme$;
  isEdelweissTheme$ = this._themeService.isEdelweissTheme$;
  isRemaxCollectionTheme$ = this._themeService.isRemaxCollectionTheme$;
  isRemaxClassicTheme$ = this._themeService.isRemaxClassicTheme$;
  isProschTheme$ = this._themeService.isProschTheme$;
  isAhImmobilienTheme$ = this._themeService.isAhImmobilienTheme$;
  isAaaaImmobilienTheme$ = this._themeService.isAaaaImmobilienTheme$;
  isNoriskTheme$ = this._themeService.isNoriskTheme$;
  isMayrImmoTheme$= this._themeService.isMayrImmoTheme$;


  constructor(private readonly _themeService: ThemeService) { }
}
