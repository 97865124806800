import { gql } from 'apollo-angular';

export interface GraphQLResponseDeleteUploadedDocument {
  deleteUploadedDocument: string;
}

export const DELETE_UPLOADED_DOCUMENT = gql`
    mutation DeleteUploadedDocument($url: String!, $type: String!, $accessId: String!) {
      deleteUploadedDocument(url: $url, type: $type, accessId: $accessId)
    }
`;