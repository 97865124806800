import { Environment } from "./environment-config";

export const environment: Environment = {
  production: true,
  backendBaseUrl: 'https://app.propup.at/graphql',
  OBJECT_BACKEND_URL: 'https://backend.propup.at/object-service/',
  SENTRY_ENVIRONMENT: 'production',
  staticToggles: {
    enableLoggingTracing: true,
    enableLoadingPage: false,
    showFeedbackModal: true,
    showObjectImages: true,
    enableServicePage: false,
    enableOwnerSigning: true,
  }
}