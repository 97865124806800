import { Pipe, PipeTransform } from '@angular/core';
import { PropertyAddress } from '@frontend/models';

@Pipe({ name: 'address' })
export class AddressPipe implements PipeTransform {
  transform(value: PropertyAddress | undefined): string {
    if (!value) {
      return 'Adresse ungültig';
    }

    const {
      streetName,
      buildingNumber,
      zipCode,
      city
    } = value;

    let result = '';

    if (streetName) {
      result += streetName;
    }
    if (buildingNumber) {
      result += ` ${buildingNumber}`;
    }
    if (zipCode) {
      result += `, ${zipCode}`;
    }
    if (city) {
      result += ` ${city}`;
    }

    return result.trim();
  }
}
