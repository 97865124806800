import { Pipe, PipeTransform } from '@angular/core';

export const transformViewingPassType = (value?: string | null): string => {
    if (!value) {
        return '';
    }

    switch (value) {
        case 'DE':
            return 'Besichtigungsprotokoll';
        case 'AT':
            return 'Besichtigungsschein';
        default:
            return 'Besichtigungsschein';
    }
}

@Pipe({ name: 'viewingPassLanguage' })
export class ViewingPassLanguagePipe implements PipeTransform {
    transform(value?: string | null): string {
        return transformViewingPassType(value)
    }
}
