import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { PropertyOverviewActions } from './action-types';
import { CustomerService, PropertyService, ThemeService } from '@frontend/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { forkJoin } from 'rxjs';

@Injectable()
export class PropertyOverviewEffects {
  public loadPropertyOverview$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PropertyOverviewActions.LoadPropertyOverview),
      switchMap(({ accessId }) =>
        this._propertyService.getPropertyOverviewById$(accessId).pipe(
          switchMap((data) => {
            this._themeService.setAgentRelatedTheme(data?.agent);

            let customerLoadAction;
            if (data.customerType === 'business') {
              customerLoadAction = PropertyOverviewActions.LoadPropertyOverviewBusinessCustomer({ id: data.businessCustomerId ?? '' });
            } else {
              customerLoadAction = PropertyOverviewActions.LoadPropertyOverviewCustomers({ ids: data.customersIds ?? [] });
            }

            return [
              PropertyOverviewActions.LoadPropertyOverviewSucceeded({ payload: data }),
              PropertyOverviewActions.LoadPropertyOverviewDocuments({ accessId }),
              PropertyOverviewActions.LoadOverviewFeedback({ accessId }),
              customerLoadAction,
            ];
          }),
          catchError(() => {
            this._snackBar.open('Objekt konnte nicht gefunden werden', 'Schließen');

            return [PropertyOverviewActions.LoadPropertyOverviewFailed()];
          })
        )
      )
    )
  );

  public loadPropertyOverviewDocuments$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PropertyOverviewActions.LoadPropertyOverviewDocuments),
      switchMap(({ accessId }) =>
        this._propertyService.getPropertyOverviewDocuments$(accessId).pipe(
          switchMap((data) => [
            PropertyOverviewActions.LoadPropertyOverviewDocumentsSucceeded({ payload: data })
          ]),
          catchError(() => [PropertyOverviewActions.LoadPropertyOverviewDocumentsFailed()])
        )
      )
    )
  );

  public loadOverviewFeedback$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PropertyOverviewActions.LoadOverviewFeedback),
      switchMap(({ accessId }) =>
        this._propertyService.getOverviewFeedback$(accessId).pipe(
          switchMap((data) => [
            PropertyOverviewActions.LoadOverviewFeedbackSucceeded({ payload: data })
          ]),
          catchError(() => [PropertyOverviewActions.LoadOverviewFeedbackFailed()])
        )
      )
    )
  );

public generateViewingPassPreviewDocument$ = createEffect(() =>
    this._actions$.pipe(
        ofType(PropertyOverviewActions.GenerateViewingPassPreviewDocument),
        switchMap(({ accessId }) =>
            this._propertyService.getViewingPassDocumentPreview$(accessId).pipe(
                switchMap((data) => [
                    PropertyOverviewActions.GenerateViewingPassPreviewDocumentSucceeded({ payload: data })
                ]),
                catchError(() => {
                    this._snackBar.open('Besichtigungsschein konnte nicht geladen werden', 'Schließen');
                    return [PropertyOverviewActions.GenerateViewingPassPreviewDocumentFailed()];
                })
            )
        )
    )
);

  public generateRentalOfferPreviewDocument$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PropertyOverviewActions.GenerateRentalOfferPreviewDocument),
      switchMap(({ accessId }) =>
        this._propertyService.getRentalOfferDocumentPreview$(accessId).pipe(
          switchMap((data) => [
            PropertyOverviewActions.GenerateRentalOfferPreviewDocumentSucceeded({ payload: data })
          ]),
          catchError(() => {
            this._snackBar.open('Angebot konnte nicht geladen werden', 'Schließen');
            return [PropertyOverviewActions.GenerateRentalOfferPreviewDocumentFailed()];
          })
        )
      )
    )
  );

  public generateBuyingOfferPreviewDocument$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PropertyOverviewActions.GenerateBuyingOfferPreviewDocument),
      switchMap(({ accessId }) =>
        this._propertyService.getBuyingOfferDocumentPreview$(accessId).pipe(
          switchMap((data) => [
            PropertyOverviewActions.GenerateBuyingOfferPreviewDocumentSucceeded({ payload: data })
          ]),
          catchError(() => {
            this._snackBar.open('Angebot konnte nicht geladen werden', 'Schließen');
            return [PropertyOverviewActions.GenerateBuyingOfferPreviewDocumentFailed()];
          })
        )
      )
    )
  );

  public loadPropertyOverviewCustomers$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PropertyOverviewActions.LoadPropertyOverviewCustomers),
      switchMap(({ ids }) =>
        forkJoin(ids.map(id => this._customerService.getCustomerDetails$(id))).pipe(
          switchMap((data) => [
            PropertyOverviewActions.LoadPropertyOverviewCustomersSucceeded({ payload: data })
          ]),
          catchError(() => [PropertyOverviewActions.LoadPropertyOverviewCustomersFailed()])
        )
      )
    )
  );

  public loadPropertyOverviewBusinessCustomer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PropertyOverviewActions.LoadPropertyOverviewBusinessCustomer),
      switchMap(({ id }) =>
        this._customerService.getBusinessCustomerDetails$(id))).pipe(
          switchMap((data) => [
            PropertyOverviewActions.LoadPropertyOverviewBusinessCustomerSucceeded({ payload: data })
          ]),
          catchError(() => [PropertyOverviewActions.LoadPropertyOverviewBusinessCustomerFailed()]),
        ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _snackBar: MatSnackBar,
    private readonly _themeService: ThemeService,
    private readonly _customerService: CustomerService,
    private readonly _propertyService: PropertyService,
  ) { }
}
