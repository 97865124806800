import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import * as fromReducer from './reducer';

export interface PropertyOverviewFeatureState {
  propertyOverview: fromReducer.State;
}

export const reducers: ActionReducerMap<PropertyOverviewFeatureState> = {
  propertyOverview: fromReducer.reducer,
};

export const getPropertyOverviewState = createFeatureSelector<fromReducer.State>('property-overview');

export const propertyOverviewSelectors = {
  propertyOverview: createSelector(getPropertyOverviewState, fromReducer.getPropertyOverview),
  propertyOverviewError: createSelector(getPropertyOverviewState, fromReducer.getPropertyOverviewError),
  propertyOverviewLoading: createSelector(getPropertyOverviewState, fromReducer.getPropertyOverviewLoading),
  propertyOverviewDocuments: createSelector(getPropertyOverviewState, fromReducer.getPropertyOverviewDocuments),
  overviewFeedback: createSelector(getPropertyOverviewState, fromReducer.getOverviewFeedback),
  overviewCustomerData: createSelector(getPropertyOverviewState, fromReducer.getPropertyOverviewCustomerData),
  rentalOfferPreviewDocumentGeneration: createSelector(getPropertyOverviewState, fromReducer.getRentalOfferPreviewDocumentGeneration),
  buyingOfferPreviewDocumentGeneration: createSelector(getPropertyOverviewState, fromReducer.getBuyingOfferPreviewDocumentGeneration),
  viewingPassPreviewDocumentGeneration: createSelector(getPropertyOverviewState, fromReducer.getViewingPassPreviewDocumentGeneration),
};

export { PropertyOverviewActions } from './action-types';
export { PropertyOverviewStoreFacade } from './property-overview-store.facade';
export { PropertyOverviewStoreModule } from './property-overview-store.module';