import { createAction, props } from '@ngrx/store';
import {
  BusinessCustomerWithCustomerDetails,
  BuyingOfferPreviewData,
  Customer, OwnerOverview, RentalOfferPreviewData,
} from '@frontend/models';


export const LoadOwnerOverview = createAction(
  '[OwnerOverview] Load Owner Overview',
  props<{ bcid?: string, pcids?: string[] }>(),
);
export const LoadOwnerOverviewFailed = createAction('[OwnerOverview] Failed to load Owner Overview');
export const LoadOwnerOverviewSucceeded = createAction(
  '[OwnerOverview] Succeeded to load Owner Overview',
  props<{ payload: OwnerOverview[] }>()
);

export const LoadOwnersData = createAction(
  '[OwnerOverview] Load Owners Data',
  props<{ ownerIds: string[] }>(),
);
export const LoadOwnersDataFailed = createAction('[OwnerOverview] Failed to load Owners Data');
export const LoadOwnersDataSucceeded = createAction(
  '[OwnerOverview] Succeeded to load Owners Data',
  props<{ payload: Customer[] }>()
);

export const LoadBusinessOwnerData = createAction(
  '[OwnerOverview] Load Business Owner Data',
  props<{ id: string }>(),
);
export const LoadBusinessOwnerDataFailed = createAction('[OwnerOverview] Failed to load Business Owner Data');
export const LoadBusinessOwnerDataSucceeded = createAction(
  '[OwnerOverview] Succeeded to load Business Owner Data',
  props<{ payload: BusinessCustomerWithCustomerDetails }>()
);

export const LoadRentalOfferPreviewData = createAction(
  '[OwnerOverview] Load Rental Offer Preview Data',
  props<{ id: string }>(),
);
export const LoadRentalOfferPreviewDataFailed = createAction('[OwnerOverview] Failed to load Rental Offer Preview Data');
export const LoadRentalOfferPreviewDataSucceeded = createAction(
  '[OwnerOverview] Succeeded to load Rental Offer Preview Data',
  props<{ payload: RentalOfferPreviewData }>()
);

export const LoadBuyingOfferPreviewData = createAction(
  '[OwnerOverview] Load Buying Offer Preview Data',
  props<{ id: string }>(),
);
export const LoadBuyingOfferPreviewDataFailed = createAction('[OwnerOverview] Failed to load Buying Offer Preview Data');
export const LoadBuyingOfferPreviewDataSucceeded = createAction(
  '[OwnerOverview] Succeeded to load Buying Offer Preview Data',
  props<{ payload: BuyingOfferPreviewData }>()
);

export const UpdateCustomersData = createAction(
  '[OwnerOverview] Update Customers Data',
  props<{
    payload: {
      customers: Customer[],
      businessCustomer?: BusinessCustomerWithCustomerDetails,
    }
  }>()
);

export const UpdateRentalOfferDocumentLink = createAction(
  '[OwnerOverview] Update Rental Offer Document Link',
  props<{ url: string }>()
);

export const UpdateBuyingOfferDocumentLink = createAction(
  '[OwnerOverview] Update Buying Offer Document Link',
  props<{ url: string }>()
);  