import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DisplayAddressPipe } from './address/display-address.pipe';
import { ObjectTypePipe } from './object-type/object-type.pipe';
import { OrderTypePipe } from './order-type/order-type.pipe';
import { AddressPipe } from './address/address.pipe';
import {OfferDecisionPipe} from "./offer-decision/offer-decision.pipe";
import {RentDurationPipe} from "./rent-duration/rent-duration.pipe";
import {ViewingPassLanguagePipe} from "./language/viewing-pass-language.pipe";

@NgModule({
  imports: [CommonModule],
  declarations: [
    DisplayAddressPipe,
    ObjectTypePipe,
    OrderTypePipe,
    AddressPipe,
    OfferDecisionPipe,
    RentDurationPipe,
    ViewingPassLanguagePipe,
  ],
  exports: [
    DisplayAddressPipe,
    ObjectTypePipe,
    OrderTypePipe,
    AddressPipe,
    OfferDecisionPipe,
    RentDurationPipe,
    ViewingPassLanguagePipe,
  ],
  providers: [
    DisplayAddressPipe,
    ObjectTypePipe,
    OrderTypePipe,
    AddressPipe,
    OfferDecisionPipe,
    RentDurationPipe,
    ViewingPassLanguagePipe,
  ],
})
export class FormattingModule { }
