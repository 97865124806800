import {CountrySettings} from "../types";
import {Language} from "./config";

export const mapCountrySettingsToLanguage = (countrySettings: CountrySettings | undefined | null): Language => {
  switch (countrySettings) {
    case CountrySettings.DE:
      return Language.DE;
    case CountrySettings.AT:
    default:
      return Language.AT;
  }
};