import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import * as fromReducer from './reducer';

export interface OwnerOverviewFeatureState {
  ownerOverview: fromReducer.State;
}

export const reducers: ActionReducerMap<OwnerOverviewFeatureState> = {
  ownerOverview: fromReducer.reducer,
};

export const getOwnerOverviewState = createFeatureSelector<fromReducer.State>('owner-overview');

export const ownerOverviewSelectors = {
  docsOverview: createSelector(getOwnerOverviewState, fromReducer.getOwnerOverview),
  docsOverviewError: createSelector(getOwnerOverviewState, fromReducer.getOwnerOverviewError),
  docsOverviewLoading: createSelector(getOwnerOverviewState, fromReducer.getOwnerOverviewLoading),
  ownersData: createSelector(getOwnerOverviewState, fromReducer.getOwnersData),
  rentalOfferPreviewData: createSelector(getOwnerOverviewState, fromReducer.getRentalOfferPreviewData),
  buyingOfferPreviewData: createSelector(getOwnerOverviewState, fromReducer.getBuyingOfferPreviewData),
};

export { OwnerOverviewActions } from './action-types';
export { OwnerOverviewStoreFacade } from './owner-overview-store.facade';
export { OwnerOverviewStoreModule } from './owner-overview-store.module';